const values = [
  {
    title: "Client-Centered",
    content:
      "We prioritize the needs and requirements of every client above all else. Your satisfaction is not just a goal; it's our relentless pursuit.",
    img: (
      <svg
        t="1627065458197"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4807"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M911.552 337.36c-4.096-68.848-35.072-133.216-82.88-172.16C787.2 131.36 732.32 111.936 678.096 111.936c-82.72 0-134.896 35.44-166.08 78.256-31.2-42.816-83.36-78.256-166.08-78.256-54.24 0-109.104 19.408-150.56 53.232-47.84 38.96-78.816 103.328-82.912 172.176-3.488 58.848 10.928 148.256 99.536 240C362.272 732.96 512.032 889.92 512.032 889.92s149.712-156.96 300-312.528c88.608-91.76 103.024-181.168 99.52-240z m-157.056 184.432a71794.4 71794.4 0 0 0-242.464 252.256 74325.76 74325.76 0 0 0-242.496-252.256c-68.432-70.864-79.76-136.816-77.2-179.68 3.28-55.216 29.824-95.568 53.568-114.928 27.056-22.064 64.448-35.232 100.032-35.232 114.336 0 124.8 101.696 126.16 112.048 1.92 14.704 13.392 31.952 41.664 31.952 26.752 0 35.52-18.496 38.16-31.68 2.304-11.392 11.84-112.32 126.176-112.32 35.584 0 72.96 13.168 100.032 35.248 23.744 19.344 50.288 59.68 53.568 114.928 2.56 42.848-8.768 108.8-77.2 179.664z"
          p-id="4808"
        ></path>
      </svg>
    ),
  },
  {
    title: "Employee-Valued",
    content:
      "We foster an environment where every employee's concerns matter, every voice resonates, and every employee's needs are not just heard but honored.",
    img: (
      <svg
        t="1627065589549"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="7303"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
      >
        <path
          d="M682.666667 149.333333a128 128 0 1 1-91.093334 217.941334l-89.173333 66.858666c5.973333 17.024 9.344 35.264 9.6 54.250667l146.517333 48.853333A106.666667 106.666667 0 1 1 640 598.272l-0.042667 0.234667-139.349333-46.421334a170.944 170.944 0 0 1-44.074667 64.512l66.048 88.064a85.333333 85.333333 0 1 1-56.213333 31.765334l-64.554667-86.122667a170.666667 170.666667 0 1 1 68.138667-271.829333l89.322667-66.986667A128 128 0 0 1 682.666667 149.333333z m-149.333334 618.666667a21.333333 21.333333 0 1 0 0 42.666667 21.333333 21.333333 0 0 0 0-42.666667z m213.333334-213.333333a42.666667 42.666667 0 1 0 0 85.333333 42.666667 42.666667 0 0 0 0-85.333333z m-405.333334-170.666667a106.666667 106.666667 0 1 0 0 213.333333 106.666667 106.666667 0 0 0 0-213.333333z m341.333334-170.666667a64 64 0 1 0 0 128 64 64 0 0 0 0-128z"
          p-id="7304"
        ></path>
      </svg>
    ),
  },
  {
    title: "Growth-Driven",
    content:
      "We empower our team to push beyond boundaries and inspire innovation rooted in rigorous research and unwavering expertise.",
    img: (
      <svg
        t="1627065227387"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1573"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
      >
        <path
          d="M935.253333 282.453333a42.666667 42.666667 0 0 0-23.04-23.04A42.666667 42.666667 0 0 0 896 256h-213.333333a42.666667 42.666667 0 0 0 0 85.333333h110.506666L554.666667 579.84l-140.373334-140.8a42.666667 42.666667 0 0 0-60.586666 0l-256 256a42.666667 42.666667 0 0 0 0 60.586667 42.666667 42.666667 0 0 0 60.586666 0L384 529.493333l140.373333 140.8a42.666667 42.666667 0 0 0 60.586667 0L853.333333 401.493333V512a42.666667 42.666667 0 0 0 85.333334 0V298.666667a42.666667 42.666667 0 0 0-3.413334-16.213334z"
          p-id="1574"
        ></path>
      </svg>
    ),
  },
  {
    title: "Candor-Welcomed",
    content:
      "Fueling success with a foundation of unwavering unity, we cherish team cohesion as our cornerstone. Our ethos champions a culture of profound care, unbridled openness, unwavering respect, and unyielding equality among every member of our team.",
    img: (
      <svg
        t="1627065408336"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3962"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
      >
        <path
          d="M512 298.666667a213.333333 213.333333 0 0 0-128 384v170.666666a85.333333 85.333333 0 0 0 85.333333 85.333334h85.333334a85.333333 85.333333 0 0 0 85.333333-85.333334v-170.666666a213.333333 213.333333 0 0 0-128-384z m64 323.84a42.666667 42.666667 0 0 0-21.333333 37.12V853.333333h-85.333334v-193.706666a42.666667 42.666667 0 0 0-21.333333-37.12A128 128 0 0 1 384 512a128 128 0 0 1 256 0 128 128 0 0 1-64 110.506667zM512 256a42.666667 42.666667 0 0 0 42.666667-42.666667V128a42.666667 42.666667 0 0 0-85.333334 0v85.333333a42.666667 42.666667 0 0 0 42.666667 42.666667zM896 469.333333h-85.333333a42.666667 42.666667 0 0 0 0 85.333334h85.333333a42.666667 42.666667 0 0 0 0-85.333334zM213.333333 469.333333H128a42.666667 42.666667 0 0 0 0 85.333334h85.333333a42.666667 42.666667 0 0 0 0-85.333334zM326.826667 273.92L265.386667 213.333333a42.666667 42.666667 0 0 0-59.306667 62.72l61.44 59.306667a42.666667 42.666667 0 0 0 31.146667 11.946667 42.666667 42.666667 0 0 0 30.72-13.226667 42.666667 42.666667 0 0 0-2.56-60.16zM818.773333 215.466667a42.666667 42.666667 0 0 0-60.16 0l-61.44 58.453333a42.666667 42.666667 0 0 0 0 60.16 42.666667 42.666667 0 0 0 30.72 13.226667 42.666667 42.666667 0 0 0 29.44-11.946667l61.44-59.306667a42.666667 42.666667 0 0 0 0-60.586666z"
          p-id="3963"
        ></path>
      </svg>
    ),
  },
];

export default values;
